import React from "react";
//import Modal from "./components/modal";
import $ from 'jquery';
//import "./styles.css";
import "bootstrap";
import Popper from 'popper.js';
import ModalQuiz from './components/modal'
import Button from 'react-bootstrap/Button'
import "./App.css"
import UIfx from 'uifx';
// import mp3File from './sounds/mouse-click-clicking-single-click-1-www.FesliyanStudios.com.mp3';
// import mp3File from './sounds/Button-Click-SoundBible.com-1931397433.mp3';
import mp3File from './sounds/Click2-Sebastian-759472264.mp3';

const click_sound = new UIfx(mp3File);
//click_sound.play();

function resize_container() {
  var maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0); // Max width for the image
  var maxHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);    // Max height for the image
  var ratio = 0;  // Used for aspect ratio
  var width = $('#img-base').width();    // Current image width
  var height = $('#img-base').height();  // Current image height
  // let width = 1920;
  // let height = 922;
  // console.log(maxWidth+' * '+maxHeight)
  // console.log(width+' * '+height)
  // console.log(width > maxWidth)
  // console.log(height > maxHeight)

  let elem1=document.querySelectorAll(".slide_prot")
  let elem2=document.querySelectorAll(".image-container")
  let elem3=document.querySelectorAll(".size-fix")
  // Check if the current width is larger than the max
  if (width > maxWidth) {
    ratio = maxWidth / width;   // get ratio for scaling image
    // Set new width
     // Scale height based on ratio
     // $('.image-container').width(maxWidth); // Set new width
     // $('.image-container').height("height", height * ratio);  // Scale height based on ratio
     // $('.slide_prot').width(maxWidth); // Set new width
     // $('.slide_prot').height("height", height * ratio);  // Scale height based on ratio
     elem1.forEach(
       (el) => {
        //  console.log(el)
        el.style.height = (height * ratio)+"px";
        el.style.width=maxWidth+"px" ;   // Set new height
      })
      elem2.forEach(
        (el) => {
          el.style.height = (height * ratio) + "px"
          el.style.width = maxWidth + "px";   // Set new height
        })
        elem3.forEach(
          (el) => {
            // console.log(el)
            el.style.height = (height * ratio)+"px";
            el.style.width=maxWidth+"px" ;   // Set new height
          })
        // $('.slide_prot').each(function (){
          //   $(this).height("height", height * ratio)
          //   $(this).width("width", maxWidth);   // Set new height
          // }
          // );    // Scale width based on ratio
          // $('.image-container').each(function (){
            //   $(this).height("height", height * ratio)
            //   $(this).width("width", maxWidth);   // Set new height
            // }
            //   );    // Scale width based on ratio
            $('#size-fix').width(maxWidth); // Set new width
            $('#size-fix').height( height * ratio);  // Scale height based on ratio
            height = height * ratio;    // Reset height to match scaled image
            width = width * ratio;    // Reset width to match scaled image
            // console.log(ratio)
          }

          // Check if current height is larger than max
          if (height >= maxHeight) {
            ratio = maxHeight / height; // get ratio for scaling image
            // console.log(ratio)
            // Set new height
            // Scale width based on ratio
            elem1.forEach((el)=>{
              // console.log(el)
              el.style.width = (width * ratio) + "px"
              el.style.height= maxHeight+"px";   // Set new height
            })
            elem2.forEach((el)=>{
              el.style.width = (width * ratio) + "px"
              el.style.height=maxHeight+"px";   // Set new height
            })
            elem3.forEach((el)=>{
              el.style.width = (width * ratio) + "px"
              el.style.height=maxHeight+"px";   // Set new height
            })
            // $('.image-container').height("height", maxHeight);   // Set new height
    // $('.image-container').width("width", width * ratio);    // Scale width based on ratio
    // $('.slide_prot').each(function (){
      //   $(this).width("width", width * ratio)
      //   $(this).height("height", maxHeight);   // Set new height
      // }
      //   );    // Scale width based on ratio
      // $('.image-container').each(function (){
        //   $(this).width("width", width * ratio)
        //   $(this).height("height", maxHeight);   // Set new height
        // }
        //   );    // Scale width based on ratio
        $('#size-fix').height( maxHeight);   // Set new height
        $('#size-fix').width( width * ratio);    // Scale width based on ratio
        width = width * ratio;    // Reset width to match scaled image
        height = height * ratio;    // Reset height to match scaled image
      }
      if (height == maxHeight && width == maxWidth) {
        // ratio = maxHeight / height; // get ratio for scaling image
        // console.log(ratio)
        // Set new height
       // Scale width based on ratio
        elem1.forEach((el)=>{
          el.style.width= maxWidth+"px"
          el.style.height= maxHeight+"px";   // Set new height
        })
        elem2.forEach((el)=>{
          el.style.width= maxWidth+"px"
          el.style.height = maxHeight + "px";   // Set new height
        })
        elem3.forEach((el)=>{
          el.style.width= maxWidth+"px"
          el.style.height = maxHeight + "px";   // Set new height
        })
        // $('.image-container').height("height", maxHeight);   // Set new height
        // $('.image-container').width("width", width * ratio);    // Scale width based on ratio
        // $('.slide_prot').each(function (){
          //   $(this).width("width", width * ratio)
          //   $(this).height("height", maxHeight);   // Set new height
          // }
          //   );    // Scale width based on ratio
          // $('.image-container').each(function (){
            //   $(this).width("width", width * ratio)
            //   $(this).height("height", maxHeight);   // Set new height
            // }
            //   );    // Scale width based on ratio
            $('#size-fix').height( maxHeight);   // Set new height
            $('#size-fix').width(maxWidth);    // Scale width based on ratio
            width = width * ratio;    // Reset width to match scaled image
            height = height * ratio;    // Reset height to match scaled image
          }
        }
        window.onload = function (){resize_container()}

        //! Safari VH fix (add calc(var(--vh, 1vh)*100) in css instead of 100vh)//////////////////////////////////////////////
        let vh = window.innerHeight*0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
          //update on resize
          let vh = window.innerHeight*0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          resize_container()
        })
        ///////////////////////////////////////////////////////////////

        class App extends React.Component {
          state = {
            show: false,
            modalShow: false,
            slide: false,
            completed: false,
            isopen:0,
            answer_given: 0,
            current_answer: 0,
            modal_content: 'test modal',
            current_story: 0,
            results: [
              {
                titolo: "SEI PROTETTO!",
                testo:
                <div className="result_text">
          <p><b>Ben fatto!</b> Sei cauto quando vai online per parlare con i tuoi amici perchè sei ben informato dei rischi che comporta Internet. Le tue password solitamente sono forti, mescolando lettere e numeri, non le condividi mai con nessuno e certamente pensi sempre due volte prima di scaricare nuove applicazioni.</p>
          <p>Non parli mai con gli sconosciuti e coloro con cui parli li conosci molto bene. Cosa ancora più importante è il confronto frequente con i tuoi genitori riguardo ciò che fai online.</p>
          <p><b>Il nostro consiglio finale</b>:continua così e condividi con i tuoi compagni di classe e amici quello che hai imparato durante questa lezione digitale. Anche loro possono migliorare la loro esperienza online.</p>
      </div>
      ,
      sfondo: "/images/esito/protetto.png"
    },
      {
        titolo: "SEI A RISCHIO!",
        testo:
        <div className="result_text">
<p>OK, non incontreresti mai qualcuno che hai conosciuto online e non condivideresti maiinformazioni con gli sconosciuti. Comunque sia, hai caricato immagini di amici o familiari e non lo hai detto loro, non pensi che scaricando applicazioni belle è pericoloso e lo hai fattoalcune volte. Se ricevi una richiesta di amicizia da qualcuno che non conosci, dovresti dirlo ai tuoi genitori.</p>
<p><b>Il nostro consiglio</b>: Ci sono alcuni progetti nel dipartimento di sicurezza online che tipotrebbero aiutare. Sei sicuramente informato sulle misure di sicurezza che devono essere prese quando navighi sul web ma sei ancora in pericolo! Quindi bisogna assicurarsi che non cedi nessuna informazione personale. Inoltre, scegli password più forti e rivedi le tue attività online con i tuoi genitori.</p>
        </div>
      ,
      sfondo: "/images/esito/rischio.png"
    },
      {
        titolo: "ATTENZIONE, SEI IN PERICOLO!",
      testo:
        <div className="result_text">
          <p>
I tuoi profili online ti descrivono così bene che hai incluso anche la tua mail e la scuola che frequenti, oltre tutte le immagini di te e dei tuoi amici, la tua famiglia, i tuoi animali ecc.
          </p>
          <p>
Usi internet praticamente per fare tutto, dallo scaricare tutti i tipi di applicazioni, conoscere persone online, chat ecc. Sei estroverso/a e accetti richieste di amicizia da persone che non conosci, ma pensi che se loro sono amici dei tuoi amici è OK. A volte hai messaggiato con persone che usavano “parole cattive” però non lo hai mai detto ai tuoi genitori perchè magari si preoccupano troppo.
          </p>
          <p>
            <b>Il nostro consiglio</b>: Devi prestare maggiore attenzione al modo in cui usi internet. Per prima cosa, siediti con i tuoi genitori e riguarda tutti le tue informazioni personali che hai condiviso con gli altri nelle ultime settimane sulle piattaforme che frequenti maggiormente.
          </p>
          <p>
            Inoltre, rimuovi qualsiasi sconosciuto dalla tua lista degli amici. Dovresti, soprattutto, parlare con i tuoi genitori se hai intenzione di incontrare persone conosciute via web. Cambia le tue password e sceglile più forti, oltre che alfanumeriche.
          </p>
        </div>
      ,
      sfondo: "/images/esito/pericolo.png"
    }
    ],
    stories: [
      {
        title: "Creazione Profilo",
        content: "",
        bg: "/images/schermate/vignetta-01.png",
        style: { marginRight: "-50vw" }
      },
      {
        title: "Informazioni Personali",
        content: "",
        bg: "/images/schermate/vignetta-02.png",
        style: {}
      },
      {
        title: "Pubblicazione Foto",
        content: "",
        bg: "/images/schermate/vignetta-03.png",
        style: {}
      },
      {
        title: "Username e Password",
        content: "",
        bg: "/images/schermate/vignetta-04.png",
        style: {}
      },
      {
        title: "Compilazione questionari o profili",
        content: "",
        bg: "/images/schermate/vignetta-05.png",
        style: {}
      },
      {
        title: "Messaggi da sconosciuti",
        content: "",
        bg: "/images/schermate/vignetta-06.png",
        style: {}
      },
      {
        title: "Incontrare sconosciuti",
        content: "",
        bg: "/images/schermate/vignetta-07.png",
        style: {}
      },
      {
        title: "Comprare Online",
        content: "",
        bg: "/images/schermate/vignetta-08.png",
        style: {}
      },
      {
        title: "Scaricare Programmi o App",
        content: "",
        bg: "/images/schermate/vignetta-09.png",
        style: {}
      },
      {
        title: "Siti web con contenuti violenti",
        content: "",
        bg: "/images/schermate/vignetta-10.png",
        style: {marginRight:'20vw'}
      },
    ],
    questions: [
      { question: "Creeresti un profilo con informazioni false?", class: "story_1" },
      { question: "Pubblicheresti informazioni personali dei tuoi familiari, amici o conoscenti in rete?", class: "story_2" },
      { question: "Pubblicheresti le tue foto o quelle dei tuoi parenti e amici online senza il loro permesso?", class: "story_3" },
      { question: "Condivideresti la tua username e password con persone diverse dai tuoi genitori?", class: "story_4" },
      { question: "Hai mai compilato questionari, profili ed iscrizioni a piattaforme online senza il permesso di un adulto?", class: "story_5" },
      { question: "Risponderesti ad un messaggio che ti arriva in una chat da un contatto che non conosci?", class: "story_6" },
      { question: "Incontreresti qualcuno che hai conosciuto in una chat online?", class: "story_7" },
      { question: "Compreresti qualcosa on line senza farti aiutare dai tuoi genitori?", class: "story_8" },
      { question: "Scaricheresti programmi o app senza chiedere il parere di un adulto?", class: "story_9" },
      { question: "Apriresti un sito web con contenuti violenti e non adatti alla tua età senza il permesso dei tuoi genitori?", class: "story_10" }
    ]
    ,
    weights: [
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 },
      { si: 1, no: 0 }
    ],
    responses: [
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
      { good: "/images/domande/risposta-corretta.png", bad: "/images/domande/risposta-errata.png" },
    ],
    points: 0,
  };

  nextStory = () => {
    this.setModalShow(false);
    this.setState(prevState => ({
      ...prevState,
      current_story: prevState.current_story + 1,
      answer_given: 0
    }));
    // click.play()
    this.scroll(1);
    this.isCompleted();
    if (this.state.current_story >0) {

    let el = document.querySelector('.storia')
    let text = document.querySelector('.story_title')
    // let clone = el.cloneNode(true)
    // el.parentNode.replaceChild(clone, el);
    //console.log(el)
    el.classList.toggle('intro_card');
    el.classList.toggle('outro_card');
    setTimeout(() => {el.classList.toggle('intro_card')}, 10);
    setTimeout(() => {el.classList.toggle('outro_card')}, 10);

    text.classList.toggle('intro_card');
    text.classList.toggle('outro_card');
    setTimeout(() => {text.classList.toggle('intro_card')}, 10);
    setTimeout(() => {text.classList.toggle('outro_card')}, 10);
    }
    //$('.storia').classList.toggle('intro_card');
  }

  addScore = (points) => {
    this.setState(prevState => ({
      ...prevState,
      points: prevState.points + points,
      answer_given: 1,
      current_answer: points
    }))
    // setTimeout(() => {
    //   this.setModalShow(false)
    //     setTimeout(() => {
    //       this.nextStory()
    //     }, 1000);
    // }, 10000);
  }
  componentDidMount(){
    resize_container()
    this.restart()
    this.scroll(-1000)
  }
  isCorrect = () => {
    //let pesi = this.state.weights[this.state.current_story - 1]
    let answer = this.state.current_answer
    if (answer > 0) {
      return false
    } else {
      return true
    }
  }
  isCompleted = () => {
    if (this.state.current_story >= this.state.stories.length) {
      this.setState(prevState => ({
        ...prevState,
        completed: true
      }))
    }
  }
  result_background = () => {
    let value = this.state.points
    let bg = ""
    if (value >= 0 && value <= 3) {
      bg = this.state.results[0];
    } else if (value >= 4 && value <= 7) {
      bg = this.state.results[1];
    } else {
      bg = this.state.results[2];
    }
    return bg.sfondo
  }
  result_text = () => {
    let value = this.state.points
    let testo = ""
    //console.log(value)
    if (value >= 0 && value <= 3) {
      testo = this.state.results[0];
    } else if (value >= 4 && value <= 7) {
      testo = this.state.results[1];
    } else {
      testo = this.state.results[2];
    }
    //console.log("testo:" + testo)
    return testo.testo
  }
  result_title = () => {
    let value = this.state.points
    let titolo = ""
    let el = document.querySelector('.container-fluid')
    //console.log(value)
    if (value >= 0 && value <= 3) {
      titolo = this.state.results[0];
      el.style.backgroundColor="#ADD576";
    } else if (value >= 4 && value <= 7) {
      titolo = this.state.results[1];
      el.style.backgroundColor="#F8F09D";
    } else {
      titolo = this.state.results[2];
      el.style.backgroundColor="#D3532D";
    }
    //console.log("testo:" + testo)
    return titolo.titolo
  }
  restart = () => {
    let pos2 = $('.image-container').scrollLeft() * (-1);
    $('.image-container').animate({ scrollLeft: pos2 }, 0);
    this.setState(prevState => ({
      ...prevState,
      current_story: 0,
      points: 0,
      modalShow: false,
      show: false,
      slide: false,
      completed: false,
      answer_given: 0,
      current_answer: 0,
    }))
  }

  openModal() {
    this.setState(prevState => (
      { ...prevState, show: !prevState.show, isopen:1 }))
  }

  closeModal(e) {
    if (e.target.id === "modal") {
      this.setState(prevState => ({ ...prevState, show: false, isopen: 0 }))
    }
  }
  // componentDidMount() {
  //   // let pos2 = $('.image-container').scrollLeft() * (-1);
  //   // $('.image-container').animate({ scrollLeft: pos2 }, 0);
  //   this.restart()
  // }
  scroll = (direction) => {
    //let far = $('.image-container').width() / 2 * direction;
    //let far = $('.image-container').width() * direction;

    let far = $('.image-container').width() * direction;
    //console.log(far)
    let pos = $('.image-container').scrollLeft() + far;
    let scrollWidth = $('.image-container').get(0).scrollWidth;
    //console.log(far + " :far " + pos + " :pos " + scrollWidth + " :scrollWidth")
    if (pos === scrollWidth) {
      let pos2 = $('.image-container').scrollLeft() * (-1);
      $('.image-container').animate({ scrollLeft: pos2 }, 1000)
      // console.log("end "+pos)
      //console.log("funziona")
    } else {
      $('.image-container').animate({ scrollLeft: pos }, 1000)
      // console.log("mid "+pos)
    }
  }

  setModalShow = (e) => {
    this.setState(prevState => ({ ...prevState, modalShow: e }))
  }
  showModal = e => {
    this.setState(prevState => ({
      ...prevState, show: !this.state.show
    }));
  };
  render() {
    return (
      <div className={this.state.completed ? "cont" : "container-fluid cont d-flex justify-content-center container-fix"} style={{ }}>
        {this.state.completed ?
          <div id = "size-fix" className="size-fix" style = {{margin:"auto auto"}}>
            <div className='backgound image-container'>
              <img className="background image" src={this.result_background()} alt=""></img>
            </div>
            <div className="result-container" style={{ }}>
              <h1 className="result_title">
                {this.result_title()}
              </h1>
              <span >
                {this.result_text()}
              </span>
              {/* <Button className="restart fullpage bottone_img" style={{ width: "15vw", position: "absolute", bottom: "2vh", backgroundColor: "transparent", border: "none", outline: "none" }} onClick={() => {
                this.restart()
              }}>
              </Button> */}
            </div>

          </div>
          :
          <div id="size-fix" className="size-fix d-flex align-self-center justify-content-center">
            <div className="align-self-center backgound image-container">
              <div className="slide_prot">
          <img id="img-base" className="background image" src="/images/home/schermata-home.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-01.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-02.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-03.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-04.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-05.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-06.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-07.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-08.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-09.jpg" alt=""></img>
              </div>
              <div className="slide_prot">
              <img className="background image" src="/images/schermate/schermata-10.jpg" alt=""></img>
              </div>
            </div>
            <div style={{}} className="row-fix d-flex align-items-center justify-content-center">
              <div className="size-fix col-12 d-flex flex-column justify-content-center align-items-center">
                {this.state.current_story < 1 ?
                  <div></div>
                  :
                  <div className="size-fix d-flex flex-column align-items-center justify-content-center" >
                    <div className="storia d-flex flex-column align-items-center justify-content-center intro_card " style={this.state.current_story > 0 ? this.state.stories[this.state.current_story - 1].style : {}}>
                      <h2 className="story_title intro_card">
                        {this.state.stories[this.state.current_story - 1].title}
                      </h2>
                      <p className="story-text">
                        {this.state.stories[this.state.current_story - 1].content}
                      </p>
                      {this.state.answer_given === 0 ?
                        <Button className="bottone_img" style={{ width: "10rem", bottom: "1rem", backgroundColor: "transparent", border: "none", outline: "none" }} onClick={() => {
                          click_sound.play();
                          this.setModalShow(true)
                        }}>
                          <img className="apri" src="/images/schermate/bottone-apri.png" alt="" />
                        </Button>
                        :
                        <div style={{ width:"10rem", height:"10vh"}}></div>
                      }
                      <img className="apri-image"  src={this.state.stories[this.state.current_story - 1].bg} alt="" />

                    </div>
                  </div>
                }
                {
                  this.state.current_story === 0 ?
                    <div className="home-flex d-flex flex-column align-items-center justify-content-center">

                      <img src="/images/home/testo-home.png" alt="" className="titolo_home" style={{ marginTop: "2rem", marginBottom: "2rem" }}></img>
                      <div className="col-12 col-md-11 descr-intro">
                        <p>Kasper, un ragazzo di nove anni e la sua amica Sky giocano
                        spesso online, ma come possono essere sicuri che ciò che fanno online sia sicuro?</p>
                        <p>In questa storia coinvolgente Kasper riceve in regalo da suo nonno un orso verde: <b>Kuma</b>.</p>
                        <p>
                        Ma che succede con questo orso di peluche? Perché a volte Kuma diventa rosso quando
                        Kasper è online?
                        </p>
                        <p>
                        Scopriamolo in questa lezione sul mondo digitale che ci interrogherà su come utilizzare
                        internet in modo sicuro.
                        </p>
                      </div>
                      <Button className="bottone_img bottone_img" style={{ backgroundColor: "transparent", border: "none" }} variant="primary" onClick={() => {
                        click_sound.play();
                        this.nextStory()
                      }}>
                        <img className="bottone-inizia" src="/images/home/bottone-inizia.png" alt="INIZIA"></img>
                      </Button>
                        <img className="kaspersky" src="/images/home/kaspersky-logo-png.png" style={{}} alt="Kaspersky"></img>
                    </div>
                    :
                    <div></div>

                }
                {/* <Button className="" variant="primary" onClick={() => {
              if (this.state.current_story === 0) {
                this.scroll(1)
              } else {
                this.setModalShow(true)
              }
            }}>
            {this.state.current_story === 0 ? "TEST" : "TEST2"}
          </Button> */}
              </div>

            </div>
            {this.state.current_story <= this.state.stories.length ?
              <ModalQuiz
              clicksound={click_sound.play}
              answer_given={this.state.answer_given}
              questions={this.state.questions}
                stories={this.state.stories}
                weights={this.state.weights}
                responses={this.state.responses}
                current_story={this.state.current_story}
                show={this.state.modalShow}
                setmodalshow={this.setModalShow}
                onHide={() => this.setModalShow(false)}
                position={this.current_story}
                scroll={this.scroll}
                iscorrect={this.isCorrect}
                restart={this.restart}
                addscore={this.addScore}
                nextstory={this.nextStory}
              />
              :
              <Button className="bottone_img" variant="primary" onClick={() => {
                click_sound.play();
                if (!this.state.answer_given) {
                  this.setModalShow(true)
                } else {
                  this.nextStory()
                }
              }}>

              </Button>
            }
          </div>
        }
      {this.state.answer_given !== 0 && this.state.current_story >= 0 && this.state.current_story !==10 ?
        <Button className="bottone_img avanti_button" onClick={() => { click_sound.play(); this.nextStory() }}><img className="" src="/images/schermate/bottone-avanti.png" alt="AVANTI"></img></Button>
        :
        <div></div>}
        {this.state.current_story === 10 && this.state.answer_given !== 0 ?
        <Button className="bottone_img result_button" onClick={() => {click_sound.play(); this.nextStory() }}><img className="" src="/images/schermate/scopri-punteggio.png" alt="RISULTATO"></img></Button>
        :
        <div></div>}
      </div>
      // <div className="App container">
      //   <button  onClick={() => $("#myModal").modal('show')}  > show Modal </button>
      //   <Modal id="myModal" onClose={this.showModal} show={this.state.show} >{this.state.modal_content} </Modal>
      // </div>
      );
  }
} export default App;
