import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';

export default class ModalQuiz extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };
  delayText = () =>{
    $(".question_text").delay(800).fadeIn(400)
  };
  render() {
    // if (!this.props.show) {
    //   return null;
    // }
    //let a = this.props
    //console.log(a)
    let bg = this.props.current_story>0 ? this.props.stories[this.props.current_story -1].bg : ""
    return (
    <Modal
      {...this.props}
      className={this.props.current_story > 0 ? this.props.questions[this.props.current_story-1].class : ""}
      modalShow={this.props.show}
      onHide = {() =>{this.props.clicksound();this.props.setmodalshow(false)}}
      onShow = {() => this.delayText}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* {this.props.current_story === 0 ? "TITLE" : this.props.stories[(this.props.current_story - 1)].title} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { this.props.answer_given === 0 ?
        <div className="question_text res" style={{margin: "3rem 5rem"}}><p>
          {this.props.current_story === 0 ? "TITLE" : this.props.questions[(this.props.current_story - 1)].question}
        </p></div>
        :
        this.props.iscorrect() ? <div className="res" style={{margin: "3rem 5rem"}}><p>Bravo! Hai scelto la risposta esatta.</p></div> : <div className="res" style={{margin: "3rem 5rem"}}>Sbagliato! Questa non è la risposta corretta.</div>
      }
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
          {this.props.answer_given===0 ?
            <div className="row" style={{position:"relative", width:"100%", minHeight:"8rem"}}>

            {/* <Button onClick={this.props.nextstory}>Continua</Button> */}
              <Button className="bottone_img si answer_button" style={{ marginLeft: "6vw", paddingBottom: "3rem" }} onClick={()=>{this.props.clicksound(); this.props.addscore(this.props.current_story === 0 ? 0 : this.props.weights[this.props.current_story -1].si)}}><img className="bott_domande"  src="/images/domande/bottone-si.png" alt="SI"></img></Button>
              <Button className="bottone_img answer_button" style={{/*marginLeft:'-2vw',*/paddingBottom: "3rem" }} onClick={() => { this.props.clicksound(); this.props.addscore(this.props.current_story === 0 ? 0 : this.props.weights[this.props.current_story -1].no)}}><img className="bott_domande" src="/images/domande/bottone-no.png" alt="NO"></img></Button>
            <img className="risposta_corretta" src="/images/domande/marinaio.png" alt="Marinaio"/>
            </div>

            :
            <div style = {{position:"relative",width:"100%", minHeight:"8rem"}}>

              <img className="risposta_corretta" src={this.props.iscorrect() ? this.props.responses[this.props.current_story - 1].good : this.props.responses[this.props.current_story - 1].bad} alt="Risultato"/>
            </div>

              }
        </Modal.Footer>
    </Modal>
      );
  // <div>
  //   <div>{this.props.children}</div>
  //   <div>
  //     <button
  //       onClick={e => {
  //         this.props.onClose();
  //         console.log(this.props.show)
  //       }}
  //     >
  //       Close
  //     </button>
  //   </div>
  // </div>);
  }
}